import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.css']
})
export class ToolComponent implements OnInit {

  constructor() { }

  objectName = '';
  objectStoreBy = '';
  objectStoreLocation = '';
  getter = '';

  objectClassName: string;
  objectDeclaration: string;
  pathName: string;
  pathNameWithCaps: string;
  getterName: string;
  location: string;
  by: string;

  onClickMe(){
    this.pathNameWithCaps = this.pathName.charAt(0).toUpperCase() + this.pathName.slice(1);
    this.getterName = 'get' + this.pathNameWithCaps;

    this.objectDeclaration = 'private List<String> ' + this.pathName + ' = new ArrayList<>();';
    this.objectName = this.objectClassName + '.' + this.getterName + '().get(0), ' + this.objectClassName + '.' + this.getterName + '().get(1)';
    this.objectStoreBy = this.pathName + '.add(' + this.by + ');';
    this.objectStoreLocation = this.pathName + '.add("' + this.location + '");';
    this.getter = 'public List<String> ' + this.getterName + '() {return ' + this.pathName + '; }';
  }

  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  ngOnInit() {
  }

}

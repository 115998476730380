import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutMeComponent } from './about-me/about-me.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CameraGearComponent } from './camera-gear/camera-gear.component';
import { ToolComponent } from './tool/tool.component';



const routes: Routes = [
  {path: '', component: GalleryComponent},
  {path: 'aboutme', component: AboutMeComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'gear', component: CameraGearComponent},
  {path: 'tool', component: ToolComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [AboutMeComponent, GalleryComponent, CameraGearComponent, ToolComponent]

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-picture-display',
  templateUrl: './picture-display.component.html',
  styleUrls: ['./picture-display.component.css']
})
export class PictureDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
